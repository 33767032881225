import {
  Button,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import { useState } from "react";
import axios_WES, { axios_Reports, axios_Identity } from "config/network";

export default function BaseURLDialog({ open, handleClose }) {
  const [wes, setWes] = useState(process.env.REACT_APP_BASE_URL_API);

  const [identity, setIdentity] = useState(
    process.env.REACT_APP_BASE_URL_IDENTITY
  );
  const handleChange = () => {
    axios_WES.defaults.baseURL = wes;
    axios_Reports.defaults.baseURL = wes;
    axios_Identity.defaults.baseURL = identity;
    handleClose();
  };
  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>Developers Only</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Change the baseURL to your local host
        </DialogContentText>
        <TextField
          value={wes}
          onChange={(e) => setWes(e?.target?.value)}
          autoFocus
          margin="dense"
          id="baseUrl"
          label="Base URL"
          fullWidth
        />
        <TextField
          value={identity}
          onChange={(e) => setIdentity(e?.target?.value)}
          autoFocus
          margin="dense"
          id="baseUrl"
          label="Identity URL"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleChange} variant="contained" color="primary">
          Change
        </Button>
      </DialogActions>
    </Dialog>
  );
}
